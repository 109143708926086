import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import "./emailverify.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/baseUrl";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState("");
  const param = useParams();
  console.log(validUrl);
  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `${baseUrl}api/user/${param.id}/verify/${param.token}`;
        const { data } = await axios.get(url);

        setValidUrl(data);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, []);
  return (
    <div className="emailverifycontainer">
      <div className="container d-flex justify-content-center">
        <div className="row emailverifycard">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center">
            <img src="/emailverify.jpg" width="100%" height="100%"></img>
          </div>
          {validUrl ? (
            <>
              {" "}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center emailverificationtext">
                {/* Congratulations ! You Are Verified ✅️ */}
                {validUrl.message}
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-3">
                <Link to="/login">
                  <Button
                    radius="full"
                    className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
                  >
                    Login Now
                  </Button>
                </Link>
              </div>
            </>
          ) : (
            <div>Some Error Occured Or You are already Verified</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerify;
