import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import NavBar from "./components/common/NavBar";
import Footer from "./components/common/Footer";
import { NextUIProvider } from "@nextui-org/react";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import NavBarNew from "./components/common/NavBarNew";
import EmailVerify from "./components/pages/EmailVerify";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import AllProducts from "./components/pages/AllProducts";

function App() {
  const user = useSelector((state) => state?.users);
  const { loading, appErr, serverErr, registered } = user;
  useEffect(() => {
    if (registered)
      toast(`🦄 Congrats! you are Registered `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    if (appErr || serverErr)
      toast.error(`${appErr}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
  }, [registered, appErr, serverErr]);
  return (
    <NextUIProvider>
      <BrowserRouter>
        <NavBarNew />
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/all-products/:type" element={<AllProducts />}></Route>
          <Route
            path="/user/:id/verify/:token"
            element={<EmailVerify />}
          ></Route>
        </Routes>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition="Bounce"
        />
        <ToastContainer />
      </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
