import React from "react";
import "./foodcard.css";
const FoodCard = ({ p }) => {
  console.log(p);
  return (
    <div className="foodcardcontainer col-sm-12 col-md-4 col-lg-3 m-1">
      <img
        className="foodcardimage"
        src="/sample/pexels-janetrangdoan-1099680.jpg"
      ></img>
      <div className="foodcardheading">
        {p?.name ? p?.name : " Matar paneer"}
      </div>
      <div className="foodcarddesc">
        {p?.name ? p?.description : " Matar paneer"}
      </div>
      <div className="row w-100 justify-content-between mt-2">
        <div className="col-5">
          <div className="row">
            <div className="col-6 text-decoration-line-through">
              {p?.price ? "₹" + p.price : "₹210"}{" "}
            </div>
            <div className="col-6">{p?.price ? "₹" + p?.price : "₹210"}</div>
          </div>
        </div>
        <button className="col-5 addbutton">Add +</button>
      </div>
    </div>
  );
};

export default FoodCard;
