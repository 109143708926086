import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";

import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";

// fetch all categories

export const fecthProductsAction = createAsyncThunk(
  "/product/fetch",
  async (type, { rejectWithValue, getState, dispatch }) => {
    // const config ={
    //  headers:{
    //     Authorization: 'Bearer '+userAuth.token
    //  }
    // }
    try {
      const { data } = await axios.get(`${baseUrl}api/products`);
      return data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const productSlices = createSlice({
  name: "products",
  initialState: {},
  extraReducers: (builder) => {
    //fetch all products
    builder.addCase(fecthProductsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fecthProductsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.Products = action?.payload;
      state.appError = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fecthProductsAction.rejected, (state, action) => {
      state.loading = false;
      state.Products = undefined;
      state.appError = action?.payload?.message;
      state.serverErr = action?.payload?.message;
    });
  },
});

export default productSlices.reducer;
