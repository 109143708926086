import React, { useEffect, useState } from "react";
import "./allproducts.css";
import FoodCategoryCardE from "../common/FoodCategoryCardeditable";
import { foodCategory } from "../../constants/constants";
import FoodCard from "../common/FoodCard";
import { useDispatch, useSelector } from "react-redux";
import { fecthProductsAction } from "../../redux/slices/product/productSlices";
import { Spinner } from "@nextui-org/react";

const AllProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state?.products);
  const { loading, appErr, serverErr, Products } = products;
  const [type, settype] = useState(null);
  useEffect(() => {
    dispatch(fecthProductsAction());
  }, [type]);
  console.log(Products);
  return (
    <div className="allproductswrapper">
      <div className="container">
        <div className="scrollableprodcategory">
          {foodCategory?.map((item) => (
            <FoodCategoryCardE item={item} key={item?._id} />
          ))}
        </div>
        <div className="row mt-4 g-4 justify-content-center p-4 allprodbackground">
          {loading ? (
            <Spinner
              label="Loading..."
              color="warning"
              labelColor="warning"
              size="md"
            />
          ) : Products?.length > 0 ? (
            Products?.map((p) => <FoodCard p={p} key={p?._id} />)
          ) : (
            <div>No Items</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
