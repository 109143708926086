import React from "react";
import "../landing.css";
const FoodCategoryCard = ({ item }) => {
  return (
    <div className="col-6 col-sm-6 col-md-3 col-lg-2">
      <div className="foodcategorycard">
        <img
          src="/landingpageicons/burger.jpg"
          className="foodcategorycardimage"
        ></img>
        {item}
      </div>
    </div>
  );
};

export default FoodCategoryCard;
