import React from "react";
import "../landing.css";
const LandingPageContactSection = () => {
  return (
    <div className="landingcontactsection">
      <div className="container">
        <div className="row g-4">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <div className="contactcard">
              <img src="/whatsapp.png" className="contactcardicons"></img>
              whatsapp App Us
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <div className="contactcard">
              <img src="/call.png" className="contactcardicons"></img>
              Call (9AM - 11PM)📲
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <div className="contactcard">
              <img
                src="landingpageicons/delivery-bike.png"
                className="contactcardicons"
              ></img>
              Order Online
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageContactSection;
