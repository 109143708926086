import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { Button, Checkbox, Input, Textarea } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Navigate } from "react-router-dom";
import "./signup.css";
import { loginUserAction } from "../../redux/slices/users/usersSlices";

const formSchema = Yup.object({
  email: Yup.string().required("email required"),
  password: Yup.string().required("password is definetly required"),
});
const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.users);
  const { loading, appErr, serverErr, userAuth } = user;
  // formik
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(loginUserAction(values));
    },
    validationSchema: formSchema,
  });
  useEffect(() => {
    if (userAuth)
      toast(`🦄 Logged In `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    if (appErr || serverErr)
      toast.error(`${appErr}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
  }, [userAuth, appErr, serverErr]);
  if (userAuth) {
    return <Navigate to="/" />;
  }
  return (
    <div className="loginWrapper">
      <ToastContainer />
      <div className="container d-flex justify-content-center">
        <div className="row logincard">
          <div className="row g-2">
            <div className="col-sm-12 col-md-6 col-lg-6 col-12 ">
              <img
                src="/login.jpg"
                className="signupburgerimage"
                width={100}
                height={100}
              ></img>
            </div>
            <form
              className="col-sm-12 col-md-6 col-lg-6 col-12 "
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-12 logintext">Login</div>
                <div className="mt-4"></div>
                <div className="col-12 mt-3">
                  <Input
                    type="email"
                    label="Email"
                    defaultValue=""
                    // description="We'll never share your email with anyone else."
                    // className="max-w-xs"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                    description={formik.touched.email && formik.errors.email}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Input
                    type="password"
                    label="Password"
                    defaultValue=""
                    // description="We'll never share your email with anyone else."
                    // className="max-w-xs"
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    description={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
                <div className="col-12 mt-4">
                  {loading ? (
                    <Button
                      radius="lg"
                      size="lg"
                      className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg max-w-100"
                      fullWidth
                      isLoading
                    >
                      Loading
                    </Button>
                  ) : (
                    <Button
                      radius="lg"
                      size="lg"
                      className="bg-gradient-to-tr from-blue-500 to-purple-500 text-white shadow-lg max-w-100"
                      fullWidth
                      type="submit"
                    >
                      Login
                    </Button>
                  )}
                  {appErr || serverErr ? (
                    <text color="error">{appErr}</text>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
