import React from "react";
import "../landing.css";
const FoodCategoryCardE = ({ item }) => {
  return (
    <div className="foodcategorycard">
      <img
        src="/landingpageicons/burger.jpg"
        className="foodcategorycardimage"
      ></img>
      {item}
    </div>
  );
};

export default FoodCategoryCardE;
