import React from "react";
import "./footer.css";
import { Divider } from "@nextui-org/divider";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5 col-lg-5">
            <div className="row mt-2">
              <div className="col-12">
                <div className="footerlogocard">
                  <img src="/logo.png" className="footerlogo"></img>
                  <div className="col-6">DibiyapurKitchen.com</div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <div className="footerdescription">
                  Restaurants know the importance of a strong first impression.
                  It's why they invest in exterior design, decorate their
                  entranceways and train hosts to welcome guests with a warm
                  smile.
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="footerallrightsreserved">
                  Copyright © DibiyapurKitchen.com || All Rights Reserved
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="row">
              <div className="col-sm-6 col-6 col-md-4 col-lg-4 ">
                <div className="row">
                  <div className="col-12 footerheading"> Contact US</div>
                  <div className="col-12 footerheadingitems">
                    {" "}
                    +91 8057120833
                  </div>
                  <div className="col-12 footerheadingitems">
                    {" "}
                    3028 phase 3 gail gaon
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 col-md-4 col-lg-4 ">
                <div className="row">
                  <div className="col-12 footerheading"> Services</div>
                  <div className="col-12 footerheadingitems">
                    {" "}
                    Birthday Party
                  </div>
                  <div className="col-12 footerheadingitems"> Kitty Party</div>
                  <div className="col-12 footerheadingitems">
                    {" "}
                    Event management
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 col-md-4 col-lg-4 ">
                <div className="row">
                  <div className="col-12 footerheading"> Socials</div>
                  <div className="col-12 footerheadingitems"> Instagram</div>
                  <div className="col-12 footerheadingitems"> facebook</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        Mentions
        <a
          href="https://www.flaticon.com/free-icons/food-and-restaurant"
          title="food and restaurant icons"
        >
          Food and restaurant icons created by Febricon - Flaticon
        </a>
        '
        <a
          href="https://www.flaticon.com/free-icons/fast-delivery"
          title="fast delivery icons"
        >
          Fast delivery icons created by Irvan Kurnianto - Flaticon
        </a>
        <a
          href="https://www.flaticon.com/free-icons/chef-hat"
          title="chef hat icons"
        >
          Chef hat icons created by Us and Up - Flaticon
        </a>
        '{/* {mention2} */}
        <a href="https://www.freepik.com/free-vector/hand-drawn-burger-illustration_133738391.htm#query=food%20illustration&position=9&from_view=keyword&track=ais_user&uuid=06e9fd90-e0d2-4be8-a1d5-4c18e6cae987">
          Image by pikisuperstar
        </a>{" "}
        on Freepik
      </div>
    </div>
  );
};

export default Footer;
