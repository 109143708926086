import React from "react";
import "../landing.css";
import FoodCard from "../common/FoodCard";

const LandingPageSectiontwo = () => {
  return (
    <div className="landingsecondsection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {" "}
            <div className="row w-100">
              <div className="col-sm-4 col-md-8 col-lg-8 landingpagesectiontwoheading">
                Most Popular In Your area 😋
              </div>
              <a className="col-sm-3 col-md-4 col-lg-4 landingpagesectiontwoviewmore d-flex justify-content-center">
                View All
              </a>
            </div>
          </div>
          <div className="col-12">
            {" "}
            <div className="row w-100 justify-content-center gap-5 mt-5 secondsextioncontent">
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageSectiontwo;
