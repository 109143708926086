import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  Button,
  Badge,
} from "@nextui-org/react";
import "./navbar.css";
import { logoutAction } from "../../redux/slices/users/usersSlices";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CartIcon } from "../../icons/CartIcon";
export default function NavBarNew() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.users);
  const location = useLocation();
  const { hash, pathname, search } = location;
  const { userAuth } = user;
  console.log(pathname);
  return (
    <div className="container">
      <Navbar maxWidth="full">
        <NavbarBrand>
          <Link to="/">
            <div className="navbartext">
              Dibiyapur
              <img
                src="/landingpageicons/logo.png"
                height={30}
                width={30}
              ></img>
              Kitchen.com
            </div>
          </Link>
        </NavbarBrand>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <NavbarItem>
            <Link color="foreground" to="/all-products/all">
              Fast Foods
            </Link>
          </NavbarItem>
          <NavbarItem isActive>
            <Link href="#" aria-current="page" color="secondary">
              Events
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link color="foreground" href="#">
              Customers
            </Link>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent as="div" justify="end">
          <Badge
            color="danger"
            content={3}
            isInvisible={false}
            shape="circle"
            size="md"
          >
            <CartIcon size={25} />
          </Badge>
          {userAuth ? (
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Avatar
                  isBordered
                  as="button"
                  className="transition-transform"
                  color="secondary"
                  name="Jason Hughes"
                  size="sm"
                  src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                <DropdownItem key="profile" className="h-14 gap-2">
                  <p className="font-semibold">Signed in as</p>
                  <p className="font-semibold">
                    {userAuth?.email} {userAuth.verified ? "✔" : "⛔"}
                  </p>
                </DropdownItem>
                <DropdownItem key="settings">My Orders</DropdownItem>
                <DropdownItem key="team_settings">Adresses</DropdownItem>
                <DropdownItem key="system">history</DropdownItem>
                <DropdownItem key="help_and_feedback">
                  Help & Feedback
                </DropdownItem>
                <DropdownItem
                  key="logout"
                  color="danger"
                  onClick={() => dispatch(logoutAction())}
                >
                  Log Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Link to={pathname === "/signup" ? "/login" : "/signup"}>
              <Button color="warning">
                {pathname === "/signup" ? "login" : "Signup"}
              </Button>
            </Link>
          )}
        </NavbarContent>
      </Navbar>
    </div>
  );
}
