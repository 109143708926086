import React from "react";
import FoodCategoryCard from "../common/FoodCategoryCard";

const LandingPageSectionThree = () => {
  return (
    <div className="landingsectionthree">
      <div className="container">
        <div className="row foodcateofytext">Foods Available to You </div>
        <div className="row g-4 justify-content-center mt-4">
          <FoodCategoryCard />
          <FoodCategoryCard />
          <FoodCategoryCard />
          <FoodCategoryCard />
          <FoodCategoryCard />
          <FoodCategoryCard />
          <FoodCategoryCard />
          <FoodCategoryCard />
        </div>
      </div>
    </div>
  );
};

export default LandingPageSectionThree;
