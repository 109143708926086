import React, { useEffect } from "react";
import "./signup.css";
import { Button, Checkbox, Input, Textarea } from "@nextui-org/react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { registerUserAction } from "../../redux/slices/users/usersSlices";
import { Bounce, toast, ToastContainer } from "react-toastify";

const formSchema = Yup.object({
  firstName: Yup.string().required("first name required"),
  lastName: Yup.string().required("last name required"),
  email: Yup.string().required("email required"),
  password: Yup.string().required("password is definetly required"),
  phone: Yup.string().required("dob required"),
  address: Yup.string().required("address required"),
});
const Signup = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.users);
  const { loading, appErr, serverErr, registered } = user;
  // formik
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      address: "",
    },
    onSubmit: (values) => {
      dispatch(registerUserAction(values));
    },
    validationSchema: formSchema,
  });
  useEffect(() => {
    if (registered)
      toast(`🦄 Congrats! you are Registered `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    if (appErr || serverErr)
      toast.error(`${appErr}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
  }, []);
  if (registered) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="signuppageWrapper">
      <ToastContainer />
      <div className="container d-flex justify-content-center">
        <div className="row singupcard">
          <div className="row g-2">
            <div className="col-sm-12 col-md-6 col-lg-6 col-12 ">
              <img
                src="/burger.jpg"
                className="signupburgerimage"
                width={100}
                height={100}
              ></img>
            </div>
            <form
              className="col-sm-12 col-md-6 col-lg-6 col-12 "
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-12 singuptext">Signup</div>
                <div className="mt-4"></div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-6 mt-3">
                  <Input
                    type="text"
                    label="FirstName"
                    defaultValue=""
                    className="max-w-xs"
                    value={formik.values.firstName}
                    onChange={formik.handleChange("firstName")}
                    onBlur={formik.handleBlur("firstName")}
                    description={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-6 mt-3">
                  <Input
                    type="text"
                    label="LastName"
                    defaultValue=""
                    className="max-w-xs"
                    value={formik.values.lastName}
                    onChange={formik.handleChange("lastName")}
                    onBlur={formik.handleBlur("lastName")}
                    description={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </div>
                <div className="col-12 mt-3">
                  <Input
                    type="email"
                    label="Email"
                    defaultValue=""
                    // description="We'll never share your email with anyone else."
                    // className="max-w-xs"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                    description={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="col-12 mt-3">
                  <Input
                    type="number"
                    label="Phone"
                    defaultValue=""
                    // description="We'll never share your email with anyone else."
                    // className="max-w-xs"
                    value={formik.values.phone}
                    onChange={formik.handleChange("phone")}
                    onBlur={formik.handleBlur("phone")}
                    description={formik.touched.phone && formik.errors.phone}
                  />
                </div>
                <div className="col-12 mt-3">
                  <Input
                    type="password"
                    label="Password"
                    defaultValue=""
                    // description="We'll never share your email with anyone else."
                    // className="max-w-xs"
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    description={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
                <div className="col-12 mt-3">
                  <Textarea
                    isRequired
                    label="Address or Location"
                    labelPlacement="outside"
                    placeholder="Enter your Your"
                    className="max-w-lg"
                    value={formik.values.address}
                    onChange={formik.handleChange("address")}
                    onBlur={formik.handleBlur("address")}
                    description={
                      formik.touched.address && formik.errors.address
                    }
                  />
                </div>
                <div className="col-12 mt-4">
                  {" "}
                  <Checkbox defaultSelected>
                    Agree To Terms & Condition Read More{" "}
                  </Checkbox>
                </div>
                <div className="col-12 mt-4">
                  {loading ? (
                    <Button
                      radius="lg"
                      size="lg"
                      className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg max-w-100"
                      fullWidth
                      isLoading
                    >
                      Loading
                    </Button>
                  ) : (
                    <Button
                      radius="lg"
                      size="lg"
                      className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg max-w-100"
                      fullWidth
                      type="submit"
                    >
                      Signup
                    </Button>
                  )}
                  {appErr || serverErr ? (
                    <text color="error">{appErr}</text>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
